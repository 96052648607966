<template>
    <a
        :href="buildImageUrl($config.public.cloudfront, id, bigSize)"
        target="_blank"
        rel="noopener"
        class="d-inline-block"
        @click.prevent="store.lightboxUrl = buildImageUrl($config.public.cloudfront, id, bigSize)"
    >
        <img
            :src="buildImageUrl($config.public.cloudfront, id, smallSize)"
            class="border rounded-2"
            :style="`height: ${size}; width: auto;`"
            loading="lazy"
        >
    </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { buildImageUrl } from '../src/helpers.ts';
import { useMainStore } from '../store/index.ts';

export default defineComponent({
    props: {
        id: { required: true, type: String },
        smallSize: { default: 'thumb', type: String },
        bigSize: { default: 'big', type: String },
        size: { default: 'auto', type: String },
    },
    setup() {
        return {
            store: useMainStore(),
        };
    },
    methods: { buildImageUrl },
});
</script>
